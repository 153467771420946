import React, { useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useTrackedState } from '../state/store';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { isEmptyObject } from '../utils/utils';
import { Checkbox, FormLabel } from '@material-ui/core';

export const Step4: React.FC = () => {
  const history = useHistory();
  const state = useTrackedState();
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  console.log('Step 4, state.clubId', state.clubId);

  return (
    <div className="grid">
      <div className="item--contained">
        <img src="/images/Face-In-The-Crowd-01.jpg" className="logo--small" alt="Face in the crowd" />
        <div className="inputWrapper">Nearly there, we just need a few details from you...</div>
      </div>

      <div className="item--contained">
        <Formik
          initialValues={{
            email: '',
            // email: 'a@a.com',
            voucherCode: '',
            // voucherCode: 'aaa-123456-X',
            agreeToTerms: '',
            // agreeToTerms: 'yes',
            agreeToPrivacyAndGDPR: '',
            // agreeToPrivacyAndGDPR: 'yes',
          }}
          validationSchema={Yup.object().shape({
            voucherCode: Yup.string()
              .required('Please enter your voucher code')
              .matches(state.voucherRegEx, 'Please correct the voucher code'),
            email: Yup.string().email('Please correct your email address').required('Please enter your email address'),
            agreeToTerms: Yup.string().required('Please agree to the Terms and conditions'),
            agreeToPrivacyAndGDPR: Yup.string().required('Please agree to the Privacy and GDPR policies'),
          })}
          onSubmit={(values) => {
            dispatch({ type: 'SET_LOADING', loading: true });
            dispatch({
              type: 'SEND_ORDER',
              email: values.email,
              voucherCode: values.voucherCode,
              agreeToTerms: values.agreeToTerms,
              agreeToPrivacyAndGDPR: values.agreeToPrivacyAndGDPR,
            });

            history.push('/result');
          }}
        >
          {({ errors, touched, handleBlur, values, handleChange, isValid }) => (
            <Form>
              <div className="inputWrapper">
                <label>Enter your email address</label>
                <input
                  type="text"
                  value={values.email}
                  className="inputText"
                  placeholder="email address"
                  onBlur={handleBlur('email')}
                  onChange={handleChange('email')}
                />
                {errors.email && touched.email ? <div className="formikError">- {errors.email}</div> : null}
              </div>

              <div className="inputWrapper">
                <label>Enter your Order ID</label>
                <input
                  type="text"
                  className="inputText"
                  value={values.voucherCode}
                  placeholder={state.voucherMask}
                  onBlur={handleBlur('voucherCode')}
                  onChange={handleChange('voucherCode')}
                />
                {errors.voucherCode && touched.voucherCode ? (
                  <div className="formikError">- {errors.voucherCode}</div>
                ) : null}
              </div>

              <FormLabel className="checkbox">
                <Checkbox value="yes" onBlur={handleBlur('agreeToTerms')} onChange={handleChange('agreeToTerms')} />I
                agree to the{' '}
                <a href="https://www.dataimage.co.uk/terms-and-conditions/" target="_blank" rel="noopener noreferrer">
                  Terms and Conditions
                </a>
                {errors.agreeToTerms && touched.agreeToTerms ? (
                  <div className="formikError">- {errors.agreeToTerms}</div>
                ) : null}
              </FormLabel>

              <FormLabel className="checkbox">
                <Checkbox
                  value="yes"
                  onBlur={handleBlur('agreeToPrivacyAndGDPR')}
                  onChange={handleChange('agreeToPrivacyAndGDPR')}
                />
                I agree to the{' '}
                <a href="https://www.dataimage.co.uk/privacy-policy/" target="_blank" rel="noopener noreferrer">
                  Privacy and GDPR
                </a>{' '}
                policies
                {errors.agreeToPrivacyAndGDPR && touched.agreeToPrivacyAndGDPR ? (
                  <div className="formikError">- {errors.agreeToPrivacyAndGDPR}</div>
                ) : null}
              </FormLabel>

              <div className="btnContainer">
                <button type="button" className="btn" onClick={() => history.push('/step3')}>
                  Prev
                </button>
                <button type="submit" className="btn" disabled={!isValid || isEmptyObject(touched)}>
                  Complete my order
                </button>
              </div>
              {/* {console.log(
                'state.voucherRegEx',
                state.voucherRegEx,
                'voucherCode',
                values.voucherCode,
                'errors',
                errors,
              )} */}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
