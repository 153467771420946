import React, { useLayoutEffect } from 'react';

import { useTrackedState } from '../state/store';
import ActivityOverlay from '../components/activitySpinner/ActivitySpinner';

export const Result: React.FC = () => {
  const state = useTrackedState();

  const { success, loading, error, setup, clubId } = state;
  // const success = true;
  // const loading = false;
  // const error: any = undefined; //'error';
  // const setup = [{ value: '1', label: 'LEice' }];
  // const clubId = '1';

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getClubName = () => {
    const selectedClub = setup.find((item: { value: string }) => item.value === clubId);

    return selectedClub.label;
  };

  const renderGoingToPrint = () => (
    <>
      <h2>All done</h2>
      <p>Thanks very much, we've successfully received your photo.</p>
      <p>
        Once your voucher has been verified with {getClubName()}, we will print your Face In The Crowd, and send it to{' '}
        {getClubName()}.
      </p>
    </>
  );

  const renderError = () => (
    <>
      <h2>Unfortunately, An Error Occurred!</h2>
      <p>Our team has been notified, please try again later</p>
    </>
  );

  const renderResult = () => {
    if (loading) {
      return null;
    }

    return (
      <>
        {success && renderGoingToPrint()}
        {error && renderError()}
      </>
    );
  };

  return (
    <div className="grid">
      <div className={loading ? 'item--full' : 'item--contained'}>
        <ActivityOverlay active={loading} />
        <img src="/images/Face-In-The-Crowd-01.jpg" className="logo" alt="Face in the crowd" />
        {renderResult()}
      </div>
    </div>
  );
};
