import React from 'react';
import { MemoryRouter, Switch, Route, Redirect } from 'react-router-dom';
import {
  browserName,
  browserVersion,
  getUA,
  mobileVendor,
  mobileModel,
  deviceType,
  osVersion,
  osName,
} from 'react-device-detect';

import { Step1 } from './pages/Step1'; // Club DDL
import { Step2 } from './pages/Step2'; // Photo
import { Step2a } from './pages/Step2a'; // Photo Resizer
import { Step3 } from './pages/Step3'; // Photo confirm
import { Step4 } from './pages/Step4'; // Voucher code + email
import { Result } from './pages/Result';
import rollbar from './utils/rollbar';

const Navigator: React.FC = () => {
  // <span id="faceInTheCrowdAppParams" data-countrycode="GB" data-sportid="F"></span>
  const faceInTheCrowdAppParams = document.getElementById('faceInTheCrowdAppParams');
  const { countrycode, sportid } = faceInTheCrowdAppParams.dataset;

  const info = {
    ...faceInTheCrowdAppParams.dataset,
    browserName,
    browserVersion,
    getUA,
    mobileVendor,
    mobileModel,
    deviceType,
    osVersion,
    osName,
  };

  rollbar.info('App starting', info);

  return (
    <MemoryRouter>
      <div className="appContainer">
        <Switch>
          <Route
            path="/step1"
            render={(routeProps) => <Step1 {...routeProps} countryCode={countrycode} sportId={sportid} />}
          />
          <Route path="/step2" component={Step2} />
          <Route path="/Step2a" component={Step2a} />
          <Route path="/step3" component={Step3} />
          <Route path="/step4" component={Step4} />
          <Route path="/result" component={Result} />
          <Redirect from="/*" to="/step1" />
        </Switch>
      </div>
    </MemoryRouter>
  );
};

export default Navigator;
