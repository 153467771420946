import React from 'react';

const OverlaySvg: React.FC<{ width: number }> = ({ width, children }) => {
  return (
    <div className="overlay">
      <svg viewBox="0 0 1080 1920" width={width}>
        <path
          d="M1080.28 1920.28H.28V.28h1080v1920zm-18.09-337.2V920.82c0-48.92-9.57-93.27-28.45-131.83-20.31-41.47-50.75-74.87-90.48-99.27-27.12-16.65-54.14-30.09-77.97-41.94-67.3-33.47-102.42-53.01-102.42-97.41 0-15.16.11-30.89.22-46.1.48-69.4.98-141.16-7.97-205.44-4.93-35.43-12.24-65.29-22.32-91.28-11.56-29.79-27.11-54.65-46.21-73.88-17.4-17.52-38.26-30.94-61.99-39.88-24.67-9.29-53.19-14-84.76-14-31.58 0-60.09 4.71-84.76 14-23.73 8.94-44.59 22.36-61.99 39.88-19.1 19.23-34.64 44.09-46.21 73.88-10.09 25.99-17.39 55.85-22.32 91.28-8.95 64.28-8.45 136.04-7.97 205.44.11 15.21.22 30.94.22 46.1 0 44.41-35.12 63.94-102.42 97.41-23.83 11.85-50.85 25.29-77.97 41.94-39.74 24.4-70.18 57.8-90.48 99.27-18.88 38.56-28.45 82.91-28.45 131.83v662.26h1044.7z"
          fill="#1d1d1b"
        />
      </svg>
      {children}
    </div>
  );
};

export default OverlaySvg;
