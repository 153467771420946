import React, { useLayoutEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { getPhotoDims, getPhotoStyles } from '../utils/photoDimensions';
import OverlaySvg from '../components/overlay/OverlaySvg';

export const Step3: React.FC = () => {
  const windowSize = useWindowDimensions();
  const history = useHistory();
  const [constrainedValues, setConstrainedValues] = useState({ width: null as number, height: null as number });

  useLayoutEffect(() => {
    if (windowSize) {
      const { constrainedWidth, constrainedHeight } = getPhotoDims(windowSize.width);

      setConstrainedValues({ width: constrainedWidth, height: constrainedHeight });
      window.scrollTo(0, 0);
    }
  }, [windowSize, setConstrainedValues]);

  const photoContainer = getPhotoStyles(constrainedValues.height);

  const photoBase64 = sessionStorage.getItem('photoBase64');

  return (
    <div className="photoCell">
      {constrainedValues.width && (
        <>
          <img src={photoBase64} alt="" style={photoContainer} />
          <OverlaySvg width={windowSize.width} />
          <div className="overlayBtn">
            <button type="button" className="btn" onClick={() => history.push('/step2')}>
              Re-Take Photo
            </button>
          </div>
          <div className="overlayBtnRight">
            <button type="button" className="btn" onClick={() => history.push('/step4')}>
              Continue
            </button>
          </div>
        </>
      )}
    </div>
  );
};
