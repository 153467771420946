import React from 'react';
import rollbar from '../../utils/rollbar';
import {
  browserName,
  browserVersion,
  mobileVendor,
  mobileModel,
  deviceType,
  osVersion,
  osName,
} from 'react-device-detect';

export class ErrorBoundary extends React.Component {
  state = {
    hasError: null as any,
  };

  static getDerivedStateFromError(error: any) {
    // Update state so next render shows fallback UI.
    return { hasError: error };
  }

  componentDidCatch(error: any, errorInfo: any) {
    const userInfo = {
      browserName,
      browserVersion,
      mobileVendor,
      mobileModel,
      deviceType,
      osVersion,
      osName,
    };

    rollbar.error(error, { userInfo: { ...userInfo } });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="grid">
          <div className="item--contained">
            <img src="/images/Face-In-The-Crowd-01.jpg" className="logo" alt="Face in the crowd" />
          </div>

          <div className="item--contained">
            <h1 className="title">How Embarrassing</h1>
          </div>

          <div className="item--contained">
            <p>An error has occurred, our team has been notified.</p>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
