import { Reducer } from 'react';
import { useReducerAsync, AsyncActionHandlers } from 'use-reducer-async';
import { createContainer } from 'react-tracked';
import { isPropertyTrue } from '../utils/utils';
import rollbar from '../utils/rollbar';

export type CLUB = {
  value: string;
  label: string;
  voucherMask: string;
  voucherRegex: string;
};

type State = {
  countryCode: string | undefined;
  sportId: string | undefined;
  clubId: string | undefined;
  voucherMask: string | undefined;
  voucherRegEx: RegExp | undefined;
  error: Error | null;
  success: boolean;
  loading: boolean;
  overRideClub: CLUB | null;
  setup: Array<CLUB> | null;
};

const initialState: State = {
  countryCode: undefined,
  sportId: undefined,
  clubId: undefined,
  // countryCode: 'GB',
  // sportId: 'F',
  // clubId: '1',
  voucherMask: undefined,
  voucherRegEx: undefined,
  // voucherRegEx: new RegExp('^[A-Za-z]{3}-[0-9]{6}-[A-Za-z]{1}$'),
  error: null,
  success: undefined,
  loading: true,
  overRideClub: null,
  setup: null,
};

type Action =
  | { type: 'ORDER_SUCCESS' }
  | { type: 'SET_LOADING'; loading: boolean }
  | { type: 'SET_SELECTED_CLUB_DETAILS'; clubId: string; voucherMask: string; voucherRegEx: RegExp }
  | { type: 'SAVE_PHOTO'; photoBase64: string }
  | { type: 'SAVE_SETUP_DATA'; setup: any; countryCode: string; sportId: string; overRideClub?: CLUB }
  | { type: 'FAILED'; error: Error };

const reducer: Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case 'SAVE_SETUP_DATA':
      return {
        ...state,
        setup: action.setup,
        overRideClub: action.overRideClub || null,
        countryCode: action.countryCode,
        sportId: action.sportId,
      };

    case 'ORDER_SUCCESS':
      return {
        ...state,
        success: true,
      };

    case 'SET_LOADING':
      return {
        ...state,
        loading: action.loading,
      };

    case 'SET_SELECTED_CLUB_DETAILS':
      // if (action.clubId === 'FITCFC') {
      //   throw Error('Arghhh');
      // }

      return {
        ...state,
        clubId: action.clubId,
        voucherMask: action.voucherMask,
        voucherRegEx: new RegExp(action.voucherRegEx),
      };

    case 'SAVE_PHOTO': {
      sessionStorage.setItem('photoBase64', action.photoBase64);
      return {
        ...state,
      };
    }
    case 'FAILED':
      return {
        ...state,
        error: action.error,
      };

    default:
      throw new Error('unknown action type');
  }
};

type AsyncActionGetData = { type: 'GET_SETUP_DATA_FOR_COUNTRY'; countryCode: string; sportId: string };
type AsyncActionSendOrder = {
  type: 'SEND_ORDER';
  email: string;
  voucherCode: string;
  agreeToTerms: string;
  agreeToPrivacyAndGDPR: string;
};

type AsyncAction = AsyncActionGetData | AsyncActionSendOrder;

const asyncActionHandlers: AsyncActionHandlers<Reducer<State, Action>, AsyncAction> = {
  GET_SETUP_DATA_FOR_COUNTRY: ({ dispatch, getState }) => async (action) => {
    try {
      const faceInTheCrowdAppParams = document.getElementById('faceInTheCrowdAppParams');
      const { clubs, overrideclub } = faceInTheCrowdAppParams.dataset;

      dispatch({ type: 'SET_LOADING', loading: true });
      const clubsData = JSON.parse(clubs);
      const overRideClubData = overrideclub && JSON.parse(overrideclub);
      dispatch({
        type: 'SAVE_SETUP_DATA',
        setup: clubsData,
        overRideClub: overRideClubData,
        countryCode: action.countryCode,
        sportId: action.sportId,
      });
    } catch (error) {
      dispatch({ type: 'FAILED', error });
      rollbar.error('GET_SETUP_DATA_FOR_COUNTRY', error);
    } finally {
      dispatch({ type: 'SET_LOADING', loading: false });
    }
  },

  SEND_ORDER: ({ dispatch, getState }) => async (action) => {
    try {
      const endPointURL = process.env.REACT_APP_ENDPOINT;
      const URL = isPropertyTrue(process.env.REACT_APP_IS_PROD) ? endPointURL : `${endPointURL}/order`;
      // console.log(
      //   `SEND_ORDER, endPointURL=${process.env.REACT_APP_ENDPOINT}, process.env.REACT_APP_IS_PROD =${isPropertyTrue(
      //     process.env.REACT_APP_IS_PROD,
      //   )}, URL=${URL}`,
      // );

      const photoBase64 = sessionStorage.getItem('photoBase64');

      const response = await fetch(URL, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          countryCode: getState().countryCode,
          sportId: getState().sportId,
          clubId: getState().clubId,
          photoBase64,
          email: action.email,
          voucherCode: action.voucherCode,
          agreeToTerms: action.agreeToTerms,
          agreeToPrivacyAndGDPR: action.agreeToPrivacyAndGDPR,
        }),
      });
      const data = await response.json();
      // console.log('Response', data);

      if (!data) throw new Error('missing data');

      rollbar.info('ORDER_SUCCESS', {
        countryCode: getState().countryCode,
        sportId: getState().sportId,
        clubId: getState().clubId,
        voucherCode: action.voucherCode,
      });

      dispatch({ type: 'ORDER_SUCCESS' });
    } catch (error) {
      dispatch({ type: 'FAILED', error });
      rollbar.error('SEND_ORDER failed', error);
    } finally {
      dispatch({ type: 'SET_LOADING', loading: false });
    }
  },
};

const useValue = () => useReducerAsync<Reducer<State, Action>, AsyncAction>(reducer, initialState, asyncActionHandlers);

export const { Provider, useTrackedState, useUpdate: useDispatch } = createContainer(useValue);
