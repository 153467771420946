import React, { useLayoutEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
// @ts-ignore
import AvatarEditor from 'react-avatar-editor';
import { useDispatch } from '../state/store';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { getPhotoDims } from '../utils/photoDimensions';
import OverlaySvg from '../components/overlay/OverlaySvg';

export const Step2a: React.FC = () => {
  const avatarEditor = useRef(null);
  const dispatch = useDispatch();
  const windowSize = useWindowDimensions();
  const history = useHistory();
  const [constrainedValues, setConstrainedValues] = useState({ width: null as number, height: null as number });

  const [position, setPosition] = useState({ x: 0.5, y: 0.5 });
  const [scale, setScale] = useState(1);

  useLayoutEffect(() => {
    if (windowSize) {
      const { constrainedWidth, constrainedHeight } = getPhotoDims(windowSize.width);

      setConstrainedValues({ width: constrainedWidth, height: constrainedHeight });
      window.scrollTo(0, 0);
    }
  }, [windowSize, setConstrainedValues]);

  const photoBase64 = sessionStorage.getItem('photoBase64');
  const handleScale = (scaleStr: string) => {
    const scale = parseFloat(scaleStr);
    setScale(scale);
  };

  const handlePositionChange = (position: { x: number; y: number }) => {
    setPosition(position);
  };

  const saveImageAndMoveOn = () => {
    const canvasScaled = avatarEditor.current.getImageScaledToCanvas();

    dispatch({
      type: 'SAVE_PHOTO',
      photoBase64: canvasScaled.toDataURL(),
    });

    history.push('/step4');
  };

  return (
    <div className="photoCell">
      {constrainedValues.width && (
        <>
          <OverlaySvg width={windowSize.width} />
          {/* 
              Docs https://www.npmjs.com/package/react-avatar-editor
              Examples found https://codesandbox.io/s/ywx32nz40z?from-embed=&file=/src/index.js:176-375
              https://react-avatar-editor.netlify.app/ 
            */}
          <AvatarEditor
            ref={avatarEditor}
            scale={scale}
            width={constrainedValues.width}
            height={constrainedValues.height}
            position={position}
            onPositionChange={handlePositionChange}
            image={photoBase64}
            className="avatarEditor"
          />
          <div className="zoom">
            <span className="zoomLabel">Zoom:</span>
            <input
              style={{ position: 'absolute' }}
              name="scale"
              type="range"
              onChange={(event) => handleScale(event.target.value)}
              min="0.1"
              max="2"
              step="0.01"
              defaultValue={scale}
            />
          </div>
          <div className="overlayBtn">
            <button type="button" className="btn" onClick={() => history.push('/step2')}>
              Re-Try
            </button>
          </div>
          <div className="overlayBtnRight">
            <button type="button" className="btn" onClick={() => saveImageAndMoveOn()}>
              Continue
            </button>
          </div>
        </>
      )}
    </div>
  );
};
