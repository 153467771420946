import React, { useState, useRef, useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Webcam from 'react-webcam';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { useDispatch, useTrackedState } from '../state/store';
import { getPhotoDims, aspectRatio, getPhotoContainerStyles } from '../utils/photoDimensions';
import OverlaySvg from '../components/overlay/OverlaySvg';
import CameraSwitchSVG from '../components/cameraSwitch/CameraSwitchSVG';
import PhotoLibrarySVG from '../components/photoLibrary/PhotoLibrary';

export const Step2: React.FC = () => {
  const windowSize = useWindowDimensions();
  const history = useHistory();
  const state = useTrackedState();
  const dispatch = useDispatch();
  const webcamRef = useRef(null);

  const [constrainedValues, setConstrainedValues] = useState({ width: null as number, height: null as number });
  const [videoConstraints, setVideoConstraints] = useState({
    width: null,
    height: null,
    facingMode: 'user' as string | { exact: string },
    aspectRatio,
  });

  const capture = React.useCallback(() => {
    if (webcamRef!.current! !== null) {
      const imageSrc = webcamRef.current.getScreenshot();
      dispatch({
        type: 'SAVE_PHOTO',
        photoBase64: imageSrc,
      });
      history.push('/step3');
    }
  }, [webcamRef, dispatch, history]);

  useLayoutEffect(() => {
    if (windowSize) {
      const { constrainedWidth, constrainedHeight } = getPhotoDims(windowSize.width);

      // console.log('{ constrainedWidth, constrainedHeight } =', constrainedWidth, constrainedHeight);

      setConstrainedValues({ width: constrainedWidth, height: constrainedHeight });

      setVideoConstraints({
        width: { min: constrainedWidth, ideal: 1610, max: 1610 },
        height: { min: constrainedWidth, ideal: 2310, max: 2310 },
        facingMode: 'user',
        aspectRatio,
      });

      window.scrollTo(0, 0);
    }
  }, [windowSize, setVideoConstraints]);

  const toggleCamera = () => {
    const facingMode = videoConstraints.facingMode === 'user' ? { exact: 'environment' } : 'user';
    setVideoConstraints({ ...videoConstraints, facingMode });
  };

  const photoContainerStyle = getPhotoContainerStyles(constrainedValues.width, constrainedValues.height);

  console.log(
    'videoConstraints',
    videoConstraints,
    'photoContainerStyle',
    photoContainerStyle,
    'constrainedValues',
    constrainedValues,
  );

  const onSelectPhoto = (photoFileName: File) => {
    const imageSrc = window.URL.createObjectURL(photoFileName);

    dispatch({
      type: 'SAVE_PHOTO',
      photoBase64: imageSrc,
    });

    history.push('/Step2a');
  };

  const showLibrary = true; // state.clubId === 'FITCFC'

  return (
    <div className="photoCell">
      {videoConstraints.width && (
        <>
          <Webcam
            audio={false}
            forceScreenshotSourceSize={true}
            // minScreenshotHeight={imageHeight}
            // minScreenshotWidth={imageWidth}
            screenshotQuality={0.9}
            height={constrainedValues.height}
            width={constrainedValues.width}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            videoConstraints={videoConstraints}
            onUserMediaError={(error) => console.error(error)}
            style={photoContainerStyle}
          />
          <OverlaySvg width={windowSize.width} />

          {console.log('state.clubId', state.clubId)}

          {showLibrary && (
            <div className="overlayBtn">
              <button className="btn btnLibrary" onClick={() => document.getElementById('hiddenFileInput').click()}>
                <PhotoLibrarySVG height={35} />
                <input
                  type="file"
                  id="hiddenFileInput"
                  className="fileUpload"
                  onChange={(event) => {
                    onSelectPhoto(event.target.files[0]);
                  }}
                  accept=".jpg,.jpeg,.png,.gif,image/jpeg,image/png,image/gif;capture=camera"
                  title="Choose a photo to upload"
                />
              </button>
            </div>
          )}

          <div className={showLibrary ? 'overlayBtnCentered' : 'overlayBtnRight'}>
            <button className="btn" onClick={capture}>
              Take photo
            </button>
          </div>

          <div className={showLibrary ? 'overlayBtnRight' : 'overlayBtn'}>
            <button className="btn btnCameraSwitch" onClick={toggleCamera}>
              <CameraSwitchSVG width={35} />
            </button>
          </div>
        </>
      )}
    </div>
  );
};
