import React from 'react';

const PhotoLibrarySVG: React.FC<{ height: number }> = ({ height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 64 60" x="0px" y="0px" height={height}>
      <path
        fill="#FFFFFF"
        d="M8 4c-2.946 0-5.333 2.4-5.333 5.333v6.078c-1.588 0.926-2.667 2.627-2.667 4.589v34.667c0 2.933 2.388 5.333 5.333 5.333h53.333c2.947 0 5.333-2.4 5.333-5.333v-34.667c0-1.962-1.078-3.662-2.667-4.589v-0.745c0-2.933-2.387-5.333-5.333-5.333h-21.333l-8-5.333zM8 12h48c2.162 0 4.012 1.299 4.849 3.146-0.667-0.301-1.403-0.479-2.182-0.479h-53.333c-0.779 0-1.515 0.178-2.182 0.479 0.838-1.847 2.687-3.146 4.849-3.146zM14 22.667h36c0.917 0 1.68 0.269 2.333 0.802 0.653 0.8 1 1.596 1 2.396v25.333c0 1.067-0.347 1.601-1 2.401-0.653 0.533-1.416 1.068-2.333 1.068h-36c-0.917 0-1.681-0.534-2.333-1.068-0.653-0.8-1-1.334-1-2.401v-25.333c0-0.8 0.347-1.596 1-2.396 0.653-0.533 1.417-0.802 2.333-0.802zM13.5 25.333c-0.125 0.267-0.167 0.265-0.167 0.531v25.333c0 0.267 0.042 0.27 0.167 0.536 0.139 0 0.319 0.266 0.5 0.266h36c0.181 0 0.375-0.266 0.5-0.266 0.139-0.267 0.167-0.27 0.167-0.536v-25.333c0-0.267-0.028-0.265-0.167-0.531h-36.5zM20 28c1.111 0 2.055 0.268 2.833 1.068s1.167 1.599 1.167 2.932c0 1.067-0.389 1.867-1.167 2.667s-1.722 1.333-2.833 1.333c-1.111 0-2.055-0.533-2.833-1.333s-1.167-1.6-1.167-2.667c0-1.333 0.389-2.132 1.167-2.932s1.722-1.068 2.833-1.068zM36.667 31.198l11.333 12.802v5.333h-32v-4l6.667-6.667 3.333 3.198z"
      />
    </svg>
  );
};

export default PhotoLibrarySVG;
