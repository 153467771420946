import React from 'react';

const ActivityOverlay: React.FC<{ active: boolean }> = ({ active }) => {
  if (!active) {
    return null;
  }

  return (
    <div className="activityOverlay">
      <div className="lds-dual-ring"></div>
    </div>
  );
};

export default ActivityOverlay;
