import Rollbar, { Callback, LogArgument, LogResult } from 'rollbar';
import { isPropertyTrue } from './utils';

const rollbar = new Rollbar({
  accessToken: 'bf6b6fa02f8d4af58f70fdea52b812c5',
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    enabled: isPropertyTrue(process.env.REACT_APP_IS_PROD),
    environment: isPropertyTrue(process.env.REACT_APP_IS_PROD) ? 'production' : 'development',
    client: {
      javascript: {
        code_version: '1',
        guess_uncaught_frames: true,
      },
    },
  },
});

const debug = (obj: LogArgument, extra?: LogArgument, callback?: Callback): LogResult => {
  if (isPropertyTrue(process.env.REACT_APP_IS_PROD)) {
    return rollbar.debug(obj, extra, callback);
  } else {
    console.log(obj, extra);
  }
};

const info = (obj: LogArgument, extra?: LogArgument, callback?: Callback): LogResult => {
  if (isPropertyTrue(process.env.REACT_APP_IS_PROD)) {
    return rollbar.info(obj, extra, callback);
  } else {
    console.log(obj, extra);
  }
};

const error = (obj: LogArgument, extra?: LogArgument, callback?: Callback): LogResult => {
  if (isPropertyTrue(process.env.REACT_APP_IS_PROD)) {
    return rollbar.error(obj, extra);
  } else {
    console.error(obj, extra, callback);
  }
};

export default { debug, error, info };
