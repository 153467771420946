import React, { useState, useEffect } from 'react';
import { MobileView, BrowserView, isChrome, isSafari } from 'react-device-detect';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useTrackedState, CLUB } from '../state/store';
import ActivityOverlay from '../components/activitySpinner/ActivitySpinner';

type BaseProps = {
  countryCode: string;
  sportId: string;
};

export const Step1: React.FC<BaseProps> = ({ countryCode, sportId }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const state = useTrackedState();
  const [clubs, setClubs] = useState([] as Array<CLUB>);

  const getSelectedValue = (options: Array<{ value: string; label: string }>, value: string | undefined) =>
    options && options.find((option) => option.value === value);

  useEffect(() => {
    if (!state.setup) {
      dispatch({ type: 'GET_SETUP_DATA_FOR_COUNTRY', countryCode: countryCode, sportId: sportId });
    }
  }, [state.setup, dispatch, countryCode, sportId]);

  useEffect(() => {
    if (state.setup && state.sportId && !state.clubId) {
      if (state.overRideClub === null) {
        setClubs(state.setup); // Data has loaded
      } else {
        const { value, voucherMask, voucherRegex } = state.overRideClub;

        dispatch({
          type: 'SET_SELECTED_CLUB_DETAILS',
          clubId: value,
          voucherMask,
          voucherRegEx: new RegExp(voucherRegex),
        });
      }
    }
  }, [setClubs, state.setup, state.overRideClub, state.sportId, state.clubId, dispatch]);

  const renderSelectClub = () => (
    <Formik
      initialValues={{
        clubId: '',
      }}
      validationSchema={Yup.object().shape({
        clubId: Yup.string().required('Please select a club'),
      })}
      onSubmit={(values) => {
        const selectedClub = state.setup.find((item: { value: string }) => item.value === values.clubId);

        dispatch({
          type: 'SET_SELECTED_CLUB_DETAILS',
          clubId: values.clubId,
          voucherMask: selectedClub.voucherMask,
          voucherRegEx: new RegExp(selectedClub.voucherRegex),
        });

        history.push('/step2');
      }}
    >
      {({ handleBlur, setFieldValue, values }) => (
        <Form>
          <div className="inputWrapper">
            <label>Select your club</label>
            <Select
              options={clubs}
              name="clubId"
              className="select"
              value={getSelectedValue(clubs, values.clubId)}
              onBlur={handleBlur('clubId')}
              onChange={(option) => setFieldValue('clubId', (option as any).value)}
            />
          </div>
          <div className="btnContainerCentered">
            <button type="submit" className="btn" disabled={!values.clubId}>
              Proceed
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );

  const renderOverRideClub = () => (
    <div className="btnContainerCentered">
      <button type="submit" className="btn" onClick={() => history.push('/step2')}>
        Proceed
      </button>
    </div>
  );

  const renderBrowserWarning = () => (
    <p className="notMobile">
      Please make sure you are using an up-to-date version of Safari or Chrome. We cannot guarantee the App working
      correctly with any other third party browsers.
    </p>
  );

  return (
    <>
      <ActivityOverlay active={state.loading} />
      <div className="grid">
        <div className="item--contained">
          <img src="/images/Face-In-The-Crowd-01.jpg" className="logo" alt="Face in the crowd" />
        </div>

        <div className="item--contained">
          <h1 className="title">Welcome to Virtual Fan App</h1>
        </div>

        <div className="item--contained">
          <MobileView>
            {!isChrome && !isSafari && renderBrowserWarning()}
            {clubs.length > 0 && renderSelectClub()}
            {clubs.length === 0 && renderOverRideClub()}
          </MobileView>

          <BrowserView>
            <h2 className="notMobile">Our system is designed to work on an iOS or Android smart phone.</h2>
            <h3 className="notMobile">Please revisit this page on your mobile phone, in portrait mode.</h3>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
          </BrowserView>
        </div>
      </div>
    </>
  );
};
