import React from 'react';

const CameraSwitchSVG: React.FC<{ width: number }> = ({ width }) => {
  return (
    <svg version="1.1" id="Layer_1" viewBox="0 0 46 46" width={width}>
      <g>
        <g>
          <g>
            <g>
              <path
                fill="#FFFFFF"
                d="M39.5,25.07V9.86c0-2.03-1.64-3.67-3.67-3.67h-3.88c-0.24,0-0.48-0.1-0.65-0.27l-2.62-2.62
					c-0.52-0.52-1.21-0.81-1.94-0.81h-7.73c-0.73,0-1.43,0.29-1.94,0.81l-2.62,2.62C14.27,6.1,14.03,6.2,13.79,6.2h-3.63
					C8.14,6.2,6.5,7.84,6.5,9.86v15.21C2.01,26.96,1,29.15,1,30.69c0,4.25,7.41,7.54,17.7,8.14l-1.13,1.13
					c-0.36,0.36-0.36,0.94,0,1.3l1.3,1.3c0.36,0.36,0.94,0.36,1.3,0l4.15-4.15c0.72-0.72,0.72-1.88,0-2.59l-3.75-3.74
					c-0.36-0.36-0.94-0.36-1.3,0l-1.3,1.3c-0.36,0.36-0.36,0.94,0,1.3l0.49,0.49C9.3,34.52,4.78,31.64,4.67,30.7
					c0.02-0.2,0.58-1.04,2.7-2.02c0.67,0.8,1.67,1.32,2.8,1.32h25.67c1.13,0,2.13-0.52,2.8-1.33c2.11,0.97,2.68,1.83,2.7,1.99
					c-0.13,0.69-3.37,3.43-12.18,4.34l-0.91,0.09c-0.5,0.05-0.87,0.5-0.82,1.01l0.19,1.82c0.05,0.5,0.5,0.87,1.01,0.82l0.91-0.09
					C38.79,37.7,45,34.5,45,30.69C45,29.15,43.99,26.95,39.5,25.07z M22.98,24.55c-4.04,0-7.33-3.29-7.33-7.33s3.29-7.33,7.33-7.33
					s7.33,3.29,7.33,7.33S27.02,24.55,22.98,24.55z M33.98,13.55c-1.01,0-1.83-0.82-1.83-1.83s0.82-1.83,1.83-1.83
					c1.01,0,1.83,0.82,1.83,1.83C35.81,12.73,34.99,13.55,33.98,13.55z"
              />
              <circle fill="#FFFFFF" cx="22.98" cy="17.21" r="5.5" />
            </g>
          </g>
        </g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
      </g>
    </svg>
  );
};

export default CameraSwitchSVG;
