import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from './state/store';

import Navigator from './Navigator';
import { ErrorBoundary } from './components/errorboundary/ErrorBoundary';

ReactDOM.render(
  <ErrorBoundary>
    <Provider>
      <Navigator />
    </Provider>
  </ErrorBoundary>,
  document.getElementById('root'),
);
