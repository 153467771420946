export const aspectRatio = 1.43;

export const getPhotoDims = (width: number) => {
  const constrainedWidth = Math.round(width * 0.98);
  const constrainedHeight = Math.round(constrainedWidth * aspectRatio);

  return { constrainedWidth, constrainedHeight };
};

export const getPhotoContainerStyles = (width: number, height: number) => ({
  width: `${width}px`,
  height: `${height}px`,
  margin: 'auto',
});

export const getPhotoStyles = (height: number) => {
  const width = Math.round(height / aspectRatio);

  return {
    width: `${width}px`,
    height: `${height}px`,
  };
};
